

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* || VARIABLES */

:root {
  /* COLORS */
  --BGCOLOR: #28365755;
  --BORDER-COLOR: #FFF;
  --COLOR: #FFF;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: whitesmoke;
  --LINK-HOVER: rgba(245, 245, 245, 0.9);
  --POST-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: #FFF;
  --TABLE-COLOR: #000;
}
:root {
  font-size: 18px; /* Default base size */
}
@media (max-width: 768px) {
  :root {
    font-size: 16px; /* Slightly smaller for mobile */
  }
}

@media (max-width: 480px) {
  :root {
    font-size: 12px; /* Smallest for very small devices */
  }
}

/* || UTILITY CLASSES */
.errmsg {
  display: inline-block;
  background-color: var(--TABLE-BGCOLOR);
  color: var(--ERROR);
  padding: 0.25em;
  margin-bottom: 0.5em;
}

.nowrap {
  white-space: nowrap;
}

button {
  cursor: pointer;
}

.icon-button {
  width: 48px;
  height: 48px;
  font-size: 2.5rem;
  background-color: transparent;
  border: none;
  color: var(--COLOR);
  display: grid;
  place-content: center;
}

.icon-button:hover,
.icon-button:focus-visible {
  transform: scale(1.2);
}

#notification-icon{
  position: relative;
}
.notifications_count{
  position: absolute;
  font-size: 1rem;
  bottom: 10px;
  right: 10px;
  color: #650000;
  font-weight: bold;
}

.icon-button[disabled] {
  visibility: hidden;
}

/* || GENERAL STYLES */

body {
  font-family: 'Nunito', sans-serif;
  min-height: 100vh;
  background-color: black;
  color: var(--COLOR);
  background-size: cover;
  background-position: top right;
  background-blend-mode: multiply;
  background-position: top center;
  background-attachment: fixed;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root {
  /* React root div */
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

a {
  text-decoration: none;
}

a,
a:visited {
  color: var(--LINK-COLOR);
}

a:hover,
a:focus-visible {
  color: var(--LINK-HOVER);
}

/* || PAGES */


.login {
  flex-grow: 1;
  padding: 1em 0;
}

/* ----------DASH HEADER-----------FOOTER------------- */
.dash-header__title {
  min-width: 100px;
  cursor: pointer;
  text-align: center;
  margin: 5px;
}

.dash-header__title:hover {
  transform: scale(1.05);
}

.dash-header {
  width: 100%;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  position: sticky;
  top: 1em; 
  max-height: 70px;
}
.dash-container {
  flex-grow: 1;
  padding: 1em 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dash-container-background {
  flex-grow: 1;
  padding: 1em;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('https://media.giphy.com/media/3oKIPlB1nanDvSf5Ys/giphy.gif');
  background-size: cover; /* Ensures the GIF covers the entire container */
  background-position: center; /* Centers the GIF */
  background-repeat: no-repeat; 
}

.dash-header__nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
}


.dash-footer {
  font-size: 1rem;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background-color: #333;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1em;
}

.dash-footer__button {
  font-size: 1rem;
  width: auto;
  height: auto;
}


footer {
  position: sticky;
  bottom: 0;
}


/* || TABLES - GENERAL */

thead,
tbody,
tr {
  display: contents;
}

.table {
  font-size: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  color: var(--TABLE-COLOR);
  gap: 0.1em;
}

.table__button {
  padding: 0.25em;
  font-size: 1.5rem;
  color: var(--TABLE-COLOR);
}

.table__thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table__th,
.table__cell {
  background-color: var(--TABLE-BGCOLOR);
  text-align: left;
  border: 1px solid var(--TABLE-COLOR);
  padding: 0.5em;
}

.table__cell:last-child {
  display: grid;
  place-content: center;
}

.table__cell--inactive {
  background-color: var(--INACTIVE);
}

/* ||  POST */

.posts-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}

.post-card {
  background-color: var(--POST-BGCOLOR);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.post-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.post-owner {
  font-size: 0.9rem;
  color: gray;
}

.post-body p {
  font-size: 1.2rem;
}

.post-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}


/* -----LOGIN PAGE--------FORM---------LOGIN PAGE--------FORM------- */

.login {
  height: 100%;
  width: 100%;
  background-color:#2f0000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  border: none;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
  padding: 10px;
  column-gap: 10px;
  text-align: center;
}


.login-form{
  border:none;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
  padding: 10px;
 
}

.register-form{
  border:none;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 10px;
  padding: 10px;

}

.login-form .full-width {
  grid-column: 1 / -1;  /* Spans the element across both columns */
}

.login-form .signInCreateDiv {
  grid-column: 1 / -1; 
  display: flex;
  justify-content: center;
}



.form__persist {
  display: flex;
  width: 100%;
  gap: 0.5em;
}

.login__form__input {
  background-color: white;
  transition: 0.6s ease;
  border: 2px solid white;
  color: black;
}

.form__submit-button,
.create_account_button {
  background-color: black;
  margin-top: 20px;
  transition: 0.6s ease;
  border: 2px solid white;
  color: white;
  width: 150px
}

.form__submit-button:hover,
.create_account_button:hover{
  cursor: pointer;
  background-color: white;
  color: black;
  transform: scale(1.05);
  border: 2px solid white;
}

.form__input {
  margin: 5px;
}
.form__input--text {
  min-height: 150px;
  margin: 5px;
}

.form__input--incomplete {
  border: 1px solid red;
  outline: 3px solid red;
}

.form__input--complete {
  border: 1px solid blue;
  outline: 3px solid blue;
}

.form__checkbox {
  width: 24px;
  height: 24px;
}

.role__select{
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  
}
.form__select {
  width: fit-content;
  padding: 0.25em;
}

.form__action-buttons {
  grid-column: 1 / -1; 
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  .dash-header__nav {
    gap: 0;
  }

  .icon-button {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) {

  body {
    font-size: 1.25rem;
  }

  .dash-header {
    padding: 1em;
  }

  .dash-container {
    padding: 1em;
  }

  .table {
    font-size: 1.5rem;
  }


  .form__action-buttons {
    position: static;
  }
}



/* --------------------------------About Page Nav Bar-------------------- */
.Nav {
  width: 100%;
  background-color: #333;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 20;
  position: sticky;
  top: 1em; /* Sticks the header at the top of the page */
}
.Nav ul {
  color: #fff;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.Nav li {
  padding: 1rem;
  cursor: pointer;
}

.Nav li:hover, 
.Nav li:focus {
  padding: 1rem;
}

.Nav li a {
  color: #fff;
  text-decoration: none;
}

.Nav li:hover, 
.Nav li:focus, 
.Nav li:hover a, 
.Nav li:focus a  {
  background-color: #eee;
  color: #333;
}

.Nav li:hover a, 
.Nav li:focus a {
  cursor: pointer;
}
.Nav {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}


.Nav li:hover, 
.Nav li:focus, 
.Nav li:hover a, 
.Nav li:focus a  {
  background-color: #eee;
  color: #333;
}

/* ---------------ABOUT PAGE-----------------ABOUT PAGE---------------------ABOUT PAGE---------------- */
/* Main container: Scrollable page */
.page-container {
  min-height: 100vh;
  padding: 1em;
  gap: 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth; /* Optional: Smooth scrolling */
}

/* Individual sections inside .about */
section.hi, .education, .me, .resume, .contact {
  position: sticky;
  top: 5.5rem; 
  z-index: 1;
  padding: 20px;
  min-height: 100vh;
}

.about {
  position: sticky;
  top: 0; 
  background-color: black; 
  display: flex;
  flex-direction: column;
}

/* ------------------------------------------------HI SECTION ----------------------- */

.hand-emoji {
  display: inline-block; 
  transition: transform 0.2s ease-out; 
  will-change: transform; 
  position: relative; 
  transform-origin: bottom center;
}

/* Waving class */
.hand-emoji.wave {
  animation: wave 2s ease-in-out;
}


/* Waving animation keyframes */
@keyframes wave {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(10deg); }
  30% { transform: rotate(-10deg); }
  45% { transform: rotate(10deg); }
  60% { transform: rotate(-5deg); }
  75% { transform: rotate(5deg); }
  100% { transform: rotate(0deg); }
}


/* Header section */
.hi {
  background-color: #650000;
}

.hi h1 {
  text-align: center;
  padding-top: 20vh;
}

.hi p {
  font-size: 1.2em;
  margin: 20px;
}

/* ---------TECHNOLOGIES---------------TECHNOLOGIES--------------------------------TECHNOLOGIES SECTION------------------------------------*/
.technologies {
  position: sticky;
  top: 5.5rem;
  z-index: 1;
  padding: 20px;
  background-color: #490000;
  height: 100vh;
  padding-top: 10vh;
}


.technology_left {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 5.5rem;
  z-index: 1;
  padding: 20px;
  background-color: #490000;
  height: 100vh;
  padding-top: 35vh;
  padding-right: 60vw;
  max-width: 100vw;
}

.technology {
  position: sticky;
  top: 5.5rem;
  z-index: 1;
  background-color: #490000;
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  padding-top: 40vh;
  flex-direction: column;
  max-width: 50vw;
  margin-left: 50vw;
  font-size: 2rem;
  font-weight: bold;
}

.technology-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  padding-top: 10vh;
}

/* When the animate class is added: icons become visible and move up */
.technology-icons.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Individual technology icons */
.technology-icon {
  background-color: #490000;
  color: #ffffff;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 8px;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* When 'fade-out' class is added: fade out */
.technology-icons.fade-out {
  opacity: 0;
  transform: translateY(20px);
}

/* Hover effect for technology icons */
.technology-icon:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Styling for category headings */
.technology-category {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 1.5em;
  color: #ffffff;
}

.gear-emoji {
  display: inline-block; 
  transition: transform 0.2s ease-out; 
  will-change: transform; 
  position: relative; 
}


.gear-emoji.spin {
  animation: spin 2s ease-in-out;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(10deg); }
  30% { transform: rotate(30deg); }
  45% { transform: rotate(45deg); }
  60% { transform: rotate(60deg); }
  75% { transform: rotate(75deg); }
  100% { transform: rotate(100deg); }
}



@media (max-width: 768px) {
  .technology-icon {
    font-size: 1em;
    padding: 8px 16px;
    margin: 8px;
  }

  .technology-category {
    font-size: 1.3em;
  }

  .technology {
    font-size: x-large;
  }

  .me-cards {
    font-size: medium;
  }
}

@media (max-width: 480px){
  .technology {
    font-size: medium;
  }
  .me-cards {
    font-size: small;
  }
}



/* --------------------------------------------ABOUT ME SECTION------------------------------------------ */
.me {
  background-color: #2f0000;
  display: flex;
  flex-direction: column;
}

.me-cards {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 5vh;
  margin-bottom: auto;
  
}

.me-card {
  background-color: #2f0000;
  color: #ffffff;
  margin: 5px;
  border-radius: 8px;
  font-size: 1.2em;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.me-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.me-raising-hand {
  display: inline-block; 
  transition: transform 0.2s ease-out; 
  will-change: transform; 
  position: relative; 
  transform-origin: bottom;
}


.me-raising-hand.shake {
  animation: shake 2s ease-in-out;
}



@keyframes shake {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(10deg); }
  30% { transform: rotate(-10deg); }
  45% { transform: rotate(10deg); }
  60% { transform: rotate(-5deg); }
  75% { transform: rotate(5deg); }
  100% { transform: rotate(0deg); }
}

/* ------------------------------------------PROJECTS SECTION------------------------ */
.projects{
  position: sticky;
  top: 5.5rem;
  z-index: 1;
  padding: 20px;
  background-color: #490000;
  height: 100vh;
}

.projects p {
  padding-top: 5vh;
}




/* ----------RESUME------------------RESUME-----------------RESUME----------------------RESUME-------------------------- */
.resume {
  background-color: #200b0b;
}

.resumeButton {
  background-color: black;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 30px;
  margin-top: 20px;
  transition: 0.6s ease;
  border: 2px solid white;
}

.resumeButton:hover{
  cursor: pointer;
  transform: scale(1.05);
  border: 2px solid black;
}

.container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  color: black;
  overflow-y: auto;
  height: 50vh;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}


.container h2 {
  color: darkblue;
  text-align: center;
}

.container ul {
  padding: 20px;
}
.container h3 {
 color: darkblue;
}
.container a {
  color: darkblue;
}





/* --------------CONTACT---------------- */
.contact {
  background-color: #0f0606;
}

.contact p {
  margin-top: 20px;
  padding: 10px;
}

.phoneIcon {
  display: inline-block; 
  transition: transform 0.2s ease-out; 
  will-change: transform; 
  position: relative; 
  transform-origin: bottom;
}

.phoneIcon.shake {
  animation: shake 2s ease-in-out;
}

.linkedin-icon {
  color: #0e76a8; 
  font-size: 2rem;
  margin-right: 10px;
  transition: color 0.3s;
  display: inline-block;
}

.linkedin-icon:hover {
  color: #005582; 
}

.linkedin-link {
  text-decoration: none; 
}

.public-footer button {
  font-size: 1.5rem;
}


/* -----------WELCOME----------WELCOME-------WELCOME------ */
.welcome {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.digital-clock {
  text-align: center;
  margin: 10px;
  color:#000;
  background-color:#eee;
}







/* CHAT-----------CHAT--------CHAT-----CHAT------CHAT-----CHAT-----CHAT------CHAT-----CHAT-----CHAT------CHAT */

.chat {
  display: flex;
  width: 90%;
  max-width: 700px;
  height:65vh;
  align-items: center;
  justify-content: center;
}


.contact_panel {
  background-color: #005582;
  width: 40%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.friends {
  background-color: #490000;
  flex: 1;
}

.friend {
  color: white;
  display: flex;
  align-items: center;
  margin: 3px;
}

.friend:hover {
  background-color: #0e76a8;
  cursor: pointer;
}

.usersSearch{
  text-align: center;
  width: 100%;
  height: 40px;
  background-color: #490000;
  color: white;
  border: none;
}

.usersSearch:focus{
  outline: none;
  border: none;
}


.usersSearch:focus::placeholder {
  color: transparent
}


.conversations{
  position: relative;
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.current_conversation{
  display: flex;
  align-items: center;
  border-radius: 3px; 
  background-color: #0e76a8;
}


.conversation {
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.conversation:hover{
  background-color: #0e76a8;
  cursor: pointer;
  background-color: #978e8e;
}



.messages_panel {
  background-color: #490000;
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 60%;
}

.profilePicContainer{
  position: relative;
  display: flex;
  justify-content: center;
}

.online_indicator {
  position: absolute;
  bottom: -1px;
  right: -1px;
}

.profilePicOnTopChat{
  height: 40px;
  width: 40px;
  margin-right: 20px;
  padding: 2px;
  border-radius: 3px;
}
.profilePic {
  border-radius: 3px;
  height: 45px;
  width: 45px;
  margin-right: 10px;
  margin: 3px;
}

.contactOnTopofChat {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #005582;
}

.messages_and_textbox{
  height: 70%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.sendForm{
  width: 100%;
  display: flex;
  min-height: 40px;
}

.chat_input{
  flex: 1;
}

.send_button{
  flex: 0.5;
}

.messages_container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

.messageTo {
  text-align: right;
  background-color: #005582;
  align-self: end;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
}

.messageFrom {
  text-align: left;
  background-color: #2e84b2;
  align-self: start;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.avatar{
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border-radius: 5px;
}



.game_slides {
  display: flex;
  width: 50vw;
  justify-content: space-between;
  max-width: 500px;
  align-items: center;
}

.spongebob {
  font-size: 8em;
}

.card-flipping{
  font-size: 8em;
}